<template>
  <!--  Template router : rfofesfexa -->
  <div class="wrapper">
    <div class="container">
      <div class="card mt-4">
        <div class="card-header">Change Password</div>
        <div class="collapse show" data-parent="#accordionExample">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-8 offset-sm-2">
                <form>
                  <div class="form-group row">
                    <label
                      for="inputUsername"
                      class="col-sm-3 text-right py-0 height-38 px-1"
                      >Username:</label
                    >
                    <div class="col-sm-9 py-0 px-1">
                      <input
                        type="text"
                        class="form-control"
                        id="inputUsername"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="inputOldPassword"
                      class="col-sm-3 text-right py-0 height-38 px-1"
                      >Old Password:</label
                    >
                    <div class="col-sm-9 py-0 px-1">
                      <input
                        type="password"
                        class="form-control"
                        id="inputOldPassword"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="inputNewPassword"
                      class="col-sm-3 text-right py-0 height-38 px-1"
                      >New Password:</label
                    >
                    <div class="col-sm-9 py-0 px-1">
                      <input
                        type="password"
                        class="form-control"
                        id="inputNewPassword"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="inputConfirmNewPassword"
                      class="col-sm-3 text-right py-0 height-38 px-1"
                      >Confirm Password:</label
                    >
                    <div class="col-sm-9 py-0 px-1">
                      <input
                        type="password"
                        class="form-control"
                        id="inputConfirmNewPassword"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-9 offset-sm-3 py-0 px-1">
                      <button
                        type="button"
                        class="btn btn-light mr-1 px-2"
                        @click="submitForm"
                      >
                        <v-icon size="18" class="mr-1" color="green"
                          >mdi-content-save</v-icon
                        >Save
                      </button>
                      <button type="button" class="btn btn-light px-2">
                        <v-icon size="18" class="mr-1" color="red"
                          >mdi-close</v-icon
                        >Clear
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PhishingMail00',
  data() {
    return {
      url: null,
      UserID: '',
    };
  },
  mounted() {
    // this.parameters = this.$route.query
    // console.log(this.parameters)
    this.UserID = this.$route.query.UserID;

    console.log('mounted() => UserID = ' + this.UserID);
  },
  created() {
    this.sendData(
      'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-one'
    );
  },
  methods: {
    submitForm(event) {
      console.log('submitForm', event);
      this.sendData(
        'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-two'
      );
      this.$router.push('/sdsafrkmk');
    },
    sendData(url) {
      this.UserID = this.$route.query.UserID;
      console.log('sendData() => UserID = ' + this.UserID);
      this.url = url + '?UserID=' + this.UserID;

      /* API Write Log to Use */
      axios.post(this.url, {}).then((response) => {
        console.log(response.data);
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D8EDFA;
}
label.height-38 {
  line-height: 38px;
}
</style>
