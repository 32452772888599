import { render, staticRenderFns } from "./WalkSummaryBySubBG.vue?vue&type=template&id=86d45b54&scoped=true"
import script from "./WalkSummaryBySubBG.vue?vue&type=script&lang=js"
export * from "./WalkSummaryBySubBG.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86d45b54",
  null
  
)

export default component.exports