import { render, staticRenderFns } from "./DSExecutiveBG2GCN.vue?vue&type=template&id=6168527d&scoped=true"
import script from "./DSExecutiveBG2GCN.vue?vue&type=script&lang=js"
export * from "./DSExecutiveBG2GCN.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6168527d",
  null
  
)

export default component.exports