<template>
  <!--  Template router : ruovvcnnlc -->
  <div class="wrapper">
  <v-container>
      <div class="header-bar">
        <v-img
          class="shrink logo-phyathai mt-5 ml-5 mr-2"
          contain
          src="~@/assets/pyt_logo.svg"
          width="200"
          height="60"
        />
      </div>

      <div class="row mt-8">
        <div class="box-signin col-sm-6" style="margin: 0 auto;">
          <div class="col-sm-12 offset-sm-1">
            <h1 class="heading-title">เข้าสู่ระบบ</h1>
            <div class="line-green-header mt-5 mb-5"></div>
          </div>

          <div class="col-sm-10 offset-sm-1">
            <template>
              <v-alert
                class="box-alert-warning"
                :style="`background-color: #fff4e5;`"
              >
              <img
                  class="mr-2"
                  :style="`margin-top: -5px;`"
                  src="~@/assets/icon-warning.svg"
                  width="16"
                />
              <span class="text-topic-warning">
                 เว็บไซต์พญาไทมีการปรับปรุงใหม่ 
              </span>
              <br><br>สำหรับผู้ใช้งานเดิม หากไม่สามารถเข้าใช้งานได้<br>
              กรุณากด <a href="javascript:void(0);" class="link-forgot" title="ลืมรหัสผ่าน">ลืมรหัสผ่าน</a> เพื่อขอรหัสผ่านใหม่</v-alert>
            </template>
          </div>
         
          <div class="col-sm-12 offset-sm-1"> 
              โปรดเข้าสู่ระบบด้วยบัญชีคนไข้.
          </div>
          <div class="card box-phyathai rounded-lg">
            <div class="card-body pt-0">
              <form>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <span class="label-input">อีเมล*</span>
                    <input
                      type="text"
                      class="form-control"
                      id="inputUsername"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <span class="label-input">รหัสผ่าน*</span>
                    <input
                      type="password"
                      class="form-control"
                      id="inputNewPassword"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <button
                      type="button"
                      class="btn mt-2 btn-agree-join w-100"
                      @click="submitForm"
                    >
                    <img
                        class="mr-2"
                        src="~@/assets/icon-login-32.png"
                        width="16"
                      />
                      <b>เข้าสู่ระบบ</b>
                    </button>
                  </div>
                </div>

                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <div class="text-or-wrapper mt-5 mb-5">
                      <div class="text-or"></div>
                    </div>
                    <div :style="`margin:0 auto;text-align: center;`">หรือเข้าสู่ระบบโดย</div>
                  </div>
                </div>

                <div class="form-group row my-0">
                  <div class="col-sm-5 offset-sm-1">
<button
                      type="button"
                      class="btn btn-sign-facebook w-100"
                      @click="submitForm"
                    >
                  <img
                    class="mr-2"
                    src="~@/assets/icon-facebook.svg"
                    width="32"
                  />
                     Facebook
                    </button>
                  </div>
                  <div class="col-sm-5">
<button
                      type="button"
                      class="btn btn-sign-gmail w-100"
                      @click="submitForm"
                    >
                    <img
                    class="mr-2"
                    src="~@/assets/icon-google-plus.svg"
                    width="32"
                  />
                     Google
                    </button>
                  </div>
                </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PhishingMail2024Dec05',
  data() {
    return {
      icon: '/phyathai-favicon.ico',
      url: null,
      UserID: '',
    };
  },
  mounted() {
    this.initIcon();
    this.setHeaderTitle();
    this.UserID = this.$route.query?.UserID ?? '';

    console.log('mounted() => UserID = ' + this.UserID);
  },
  created() {
    this.sendData(
      'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-one'
    );
  },
  methods: {
    setHeaderTitle() {
      document.title = 'เข้าสู่ระบบ | โรงพยาบาลพญาไท.';
    },
    initIcon() {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.icon;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    submitForm(event) {
      event.preventDefault();
      console.log('submitForm', event);
      this.sendData(
        'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-two'
      );
      this.$router.push('/sdsafrkmk');
    },
    sendData(url) {
      this.UserID = this.$route.query?.UserID ?? '';
      console.log('sendData() => UserID = ' + this.UserID);
      this.url = url + '?UserID=' + this.UserID;

      /* API Write Log to Use */
      axios.post(this.url, {}).then((response) => {
        console.log(response.data);
      });
    },
  },
};
</script>

<style scoped>
.header-bar {
  border: 0;
  box-shadow: unset;
  background: transparent !important;
}

.line-green-header {
  background-color: rgb(113 204 152/1);
  width: 3rem;
  height: .5rem;
}

.logo-phyathai {
  cursor: pointer;
}

.card-body {
  padding: 2rem 1.25rem;
  font-family: Averta, IBM Plex Sans Thai, sans-serif;
}

.label-input {
  color: #000000bf;
  font-size: 14px;
  font-weight: 600;
}

.join-policy-text {
  font-size:14px;
}

.box-alert-warning {
  color: #663c00;
  font-size: 15px;
}

.link-forgot {
  color: #663c00;
  text-decoration-line: underline;
}

.text-or-wrapper {
  border: 2px solid #dfdfdf;
  text-align: center;
  height: 0;
}

.text-or {
  background-color: #FFFFFF;
    width: 50px;
    margin: 0 auto;
    margin-top: -13px;
}

.text-topic-warning {
  color: #663c00;
  font-weight: 500;
}

.heading-title {
  margin: 0 auto;
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  letter-spacing: .15px;
}

.box-phyathai {
  border-radius: 10px;
  border: 0;
}

.wrapper {
  background-color: #FFFFFF;
  height: 100vh;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.container {
  background-color: #FFFFFF !important;
}


h3 {
  color: #999;
  font-size: 20px;
  font-weight: bold;
}
.btn-agree-join {
  background-color: #1F1F1F;
  color: white;
  box-shadow: 0 0 0 1px;
  height: 48px;
  border-radius: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.btn-sign-gmail {
  background-color: #d32f2f;
  color: #FFFFFF;
  box-shadow: 0 0 0 1px;
  height: 48px;
  border-radius: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.btn-sign-gmail:hover {
  background-color: #d32f2f;
  color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 15px, rgba(113, 204, 152, 0.09) 0px 4px 30px;
  transition-duration: 200ms;
  opacity: 0.9;
}

.btn-sign-facebook {
  background-color: #3b5998;
  color: #FFFFFF;
  box-shadow: 0 0 0 1px;
  height: 48px;
  border-radius: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.btn-sign-facebook:hover {
  background-color: #3b5998;
  color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 15px, rgba(113, 204, 152, 0.09) 0px 4px 30px;
  transition-duration: 200ms;
  opacity: 0.9;
}

.btn-agree-join:hover {
  box-shadow: 0 0 0 1px #0000 !important;
  cursor: pointer;
  color: #FFFFFF !important;
  opacity: 0.9;
}

.link-policies {
  color: #0a66c2;
  font-weight: 500;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn-sign-gmail {
    font-size: 12px;
  }

  .box-signin.col-sm-5 {
    flex: 0 0 55.666667%;
    max-width: 55.666667%;
  }
}

@media (min-width: 1080px) {
  .heading-title {
    font-size: 1.875rem;
  }
}
</style>
