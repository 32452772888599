<template>
  <!--  Template router : efxzbyjlze -->
  <div class="wrapper">
      <v-container>
        <div class="row">
          <div class="col-sm-7 box-login">
            <div class="card rounded-0">
              <div class="card-body">
                <form>
                  <div class="form-group row my-0">
                    <div class="col-sm-3 offset-sm-1 my-0 py-2">
                      <img src="@/assets/logo-apthai.png" class="logo-form-apthai" height="60" />
                    </div>
                    <div class="col-sm-7 offset-sm-1 my-0 icon-title">
                      AP THAI Company Limited.
                    </div>
                  </div>
                  <div class="form-group row my-0">
                    <div class="col-sm-10 offset-sm-1 my-0 py-2 display-heading-title">
                      Enter username and password
                    </div>
                  </div>
                  <div class="form-group row my-0">
                    <div class="col-sm-10 offset-sm-1 my-0 py-2">
                      <v-text-field
                        class="mt-0"
                        label="Username"
                        x-large
                        hide-details
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="form-group row my-0">
                    <div class="col-sm-10 offset-sm-1 my-0 py-2">
                      <v-text-field
                        class="mt-0"
                        type="password"
                        label="Password"
                        x-large
                        hide-details
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="form-group row my-0">
                    <div class="col-sm-10 offset-sm-1 my-0 py-2">
                      <a class="link-forgot-pass" href="#">Forgot my password</a>
                    </div>
                  </div>
                  <div class="form-group row my-0">
                    <div class="col-sm-10 offset-sm-1 my-0 py-2 text-right">
                      <v-btn
                        color="primary"
                        large
                        elevation="0"
                        class="px-10"
                        @click="submitForm"
                      >
                        Sign in
                      </v-btn>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </v-container>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PhishingMail2023Dec02',
  data() {
    return {
      icon: '/microsoft.ico',
      url: null,
      UserID: '',
      routeUnderConstructPath: '/sdsafrkmk',
    };
  },
  mounted() {
    this.initIcon();
    this.setHeaderTitle();
    this.UserID = this.$route.query?.UserID ?? '';
    console.log('mounted() => UserID = ' + this.UserID);
  },
  created() {
    this.sendData(
      'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-one'
    );
  },
  methods: {
    setHeaderTitle() {
      document.title = 'Sign in to your account (AP THAI)';
    },
    initIcon() {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.icon;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    submitForm(event) {
      console.log('submitForm', event);
      this.sendData(
        'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-two'
      );
      this.$router.push(this.routeUnderConstructPath);
    },
    sendData(url) {
      this.UserID = this.$route.query?.UserID ?? '';
      console.log('sendData() => UserID = ' + this.UserID);
      this.url = url + '?UserID=' + this.UserID;

      /* API Write Log to Use */
      axios.post(this.url, {}).then((response) => {
        console.log(response.data);
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.box-login {
  margin: 0 auto;
  max-width: 50%;
}

.logo-form-apthai {
  max-height: 36px;
}
.card-body {
  /* padding: 44px; */
}
.display-heading-title {
  color: #1b1b1b;
  font-size: 1.5rem;
  font-weight: 600;
}
.link-forgot-pass {
  font-size: 15px;
}
.container {
  position: absolute;
  z-index: 9999;
}
.icon-title {
  font-size: 15px;
  font-weight: 500;
  margin-left: -80px;
}


/* Show in Large desktops and laptops */
@media (min-width: 1200px) {
  .wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url('~@/assets/bg-template.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 57%);
  }
}

/*Hide in Other Small Devices */
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .wrapper {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-image: url('~@/assets/bg-template.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 57%);
    }

    .display-heading-title {
      font-size: 1.2rem;
    }

    .box-login {
      max-width: 66.666667%;
    }

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    .wrapper {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-image: url('~@/assets/bg-template.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 57%);
    }

    .icon-title {
      margin-left: -55px;
    }

    .display-heading-title {
      font-size: 1.2rem;
    }

    .box-login {
      max-width: 66.666667%;
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    .wrapper {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-image: url('~@/assets/bg-template.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 57%);
    }

    .icon-title {
      margin-left: -55px;
    }

    .display-heading-title {
      font-size: 1.2rem;
    }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
    .wrapper {
      background-image: none;
    }

    .icon-title {
      margin-left: 0;
    }

    .box-login {
      margin: 0 auto;
      max-width: 100%;
    }

    .display-heading-title {
      font-size: 1.2rem;
    }
}

</style>
