<template>
  <v-app class="primary">
    <!--    <v-app-bar-->
    <!--      app-->
    <!--      color="primary"-->
    <!--      dark-->
    <!--    >-->
    <!--      &lt;!&ndash; <div class="d-flex align-center">-->
    <!--        <v-img-->
    <!--          alt="Vuetify Logo"-->
    <!--          class="shrink mr-2"-->
    <!--          contain-->
    <!--          src="https://i.ibb.co/8Bh5MgL/aplogo2018.png"-->
    <!--          transition="scale-transition"-->
    <!--          width="40"-->
    <!--        />-->

    <!--        <v-img-->
    <!--          alt="Vuetify Name"-->
    <!--          class="shrink mt-1 hidden-sm-and-down"-->
    <!--          contain-->
    <!--          min-width="100"-->
    <!--          src="https://i.ibb.co/crLYCHt/text-apthai.png"-->
    <!--          width="100"-->
    <!--        />-->
    <!--      </div> &ndash;&gt;-->

    <!--      <v-spacer></v-spacer>-->

    <!--      <v-btn-->
    <!--        href="https://www.apthai.com/th/home"-->
    <!--        target="_blank"-->
    <!--        text-->
    <!--      >-->
    <!--        <span class="ml-auto emp_txt">EMPOWER LIVING</span><span class="ml-3" STYLE="font-size:7.0pt">v12.0.13</span>-->
    <!--        <v-icon class="ml-auto">mdi-open-in-new</v-icon>-->
    <!--      </v-btn>-->
    <!--    </v-app-bar>-->

    <!-- <v-content> -->
    <!-- <HelloWorld/> -->
    <!-- <router-view /> -->
    <!-- </v-content> -->
    <component :is="this.$route.meta.layout || 'DefaultLayout'">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import DefaultLayout from './components/Layout/DefaultLayout.vue';

export default {
  name: 'App',

  components: {
    // HelloWorld,
    DefaultLayout,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
// .emp_txt {
//   font-size: 18px;
//   font-weight: bold;
//   font-family: 'AP';
// }
// $primary: #c92028;
@font-face {
  font-family: 'AP';
  src: local('AP'), url(./fonts/AP/AP-Regular.ttf) format('truetype');
}
</style>
<!-- >>>>>>> 2522f79c5ecd99c59da0a52e95188f52180cb83c -->
