<template>
  <!--  Template router : ruovvcnnlc -->
  <div class="wrapper">
    <v-container>
      <div class="row">
        <div class="col-sm-6 col-md-6 box-form-login">
          <div class="card rounded-lg">
            <div class="card-body">
              <form>
                <div class="form-group row my-0">
                  <div class="col-sm-12">
                    <img class="logo-form-login" src="~/@/assets/logo-form.png" alt="ap">
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-12 mb-0 pb-0">
                    <h1 class="mb-0 heading-title">ยินดีต้อนรับสู่ AP INTRANET</h1>
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-12 pb-0 pt-0 mt-1">
                    <h3 class="mb-0 heading-sub-title">กรุณาเข้าสู่ระบบ</h3>
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-12">
                    <input
                      type="text"
                      class="form-control input-form"
                      id="inputUsername"
                      placeholder="Username หรือ Email"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-12">
                    <input
                      type="password"
                      class="form-control input-form"
                      id="inputNewPassword"
                      placeholder="Password"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-12">
                    <button
                      type="button"
                      class="btn-login w-100"
                      @click="submitForm"
                    >
                      LOGIN
                    </button>
                  </div>
                </div>
                <div class="form-group row my-0">
                    <div style="text-align: center;" class="col-sm-12 pt-0 pb-0 mt-1">
                      <a class="link-forgot-pass" href="#">ลืมรหัสผ่าน</a>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PhishingMail2023Dec01',
  data() {
    return {
      icon: '/icon-ap-thai.ico',
      url: null,
      UserID: '',
    };
  },
  mounted() {
    this.initIcon();
    this.setHeaderTitle();

    this.UserID = this.$route.query?.UserID ?? '';
    console.log('mounted() => UserID = ' + this.UserID);
  },
  created() {
    this.initIcon();
    this.setHeaderTitle();
    this.sendData(
      'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-one'
    );
  },
  methods: {
    setHeaderTitle() {
      document.title = 'AP Intranet (AP Thai)';
    },
    initIcon() {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.icon;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    submitForm(event) {
      event.preventDefault();
      console.log('submitForm', event);
      this.sendData(
        'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-two'
      );
      this.$router.push('/sdsafrkmk');
    },
    sendData(url) {
      this.UserID = this.$route.query?.UserID ?? '';
      console.log('sendData() => UserID = ' + this.UserID);
      this.url = url + '?UserID=' + this.UserID;

      /* API Write Log to Use */
      axios.post(this.url, {}).then((response) => {
        console.log(response.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

@font-face {
  font-family: 'AP';
  src: local('AP'), url(~@/assets/font/AP-Regular.ttf) format('truetype');
}

.wrapper {
  font-family: 'AP';
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@/assets/bg-mail-01.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.heading-title {
  font-size: 1.25em;
  color: rgba(0,0,0,.85);
  font-weight: 600;
  font-variant: tabular-nums;
  line-height: 1.5;
}

.heading-sub-title {
  font-size: 1.125em;
    color: #3c3c3c;
    opacity: 0.6;
}

.logo-form-login {
  width: 50px;
}

.input-form {
  font-size: 1em;
  border-color: rgb(151, 151, 151);
  height: 40px;
  padding: 10px 15px;
}

.box-form-login {
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}

/* Show in Large desktops and laptops */
@media (min-width: 1200px) {
  .box-form-login {
    flex: 0 0 35% !important;
    max-width: 35% !important;
  }
}

.card-body {
  box-shadow: #00000024 0px 2px 10px 0px;
}

.link-forgot-pass {
  font-size: 16px;
  cursor: pointer;
  color: #4f070b;
  text-decoration: none;
}

.link-forgot-pass:hover {
  opacity: 0.6;
}

.btn-login {
  color: #fff;
  background-color: #4f070b;
  border: none;
  text-transform: uppercase;
  padding: 10px 0px;
  height: auto;
  font-size: 1.125em;
  margin-top: 10px;
  font-weight: 400;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box;
}

h3 {
  color: #999;
  font-size: 20px;
  font-weight: bold;
}

</style>
