<template>
  <div class="col-12">
    <div class="card textcenter mt-3">
      <div class="card-header bg-primary text-white">
        <font-awesome-icon icon="plus" class="mr-3" />Add Appointment
      </div>

      <div class="card-body">
        <form id="aptForm">
          <div class="form-group form-row">
            <label class="col-md-2 col-form-label text-md-right" for="petName">Pet Name2</label>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="petName"
                id="petName"
                placeholder="Pet's Name"
              />
            </div>
          </div>

          <div class="form-group form-row">
            <label class="col-md-2 col-form-label text-md-right" for="ownerName">Pet Owner</label>
            <div class="col-md-10">
              <input type="text" class="form-control" id="ownerName" placeholder="Owner's Name" />
            </div>
          </div>

          <div class="form-group form-row">
            <label class="col-md-2 col-form-label text-md-right" for="aptDate">Date</label>
            <div class="col-md-4">
              <input type="date" class="form-control" id="aptDate" />
            </div>
            <label class="col-md-2 col-form-label text-md-right" for="aptTime">Time</label>
            <div class="col-md-4">
              <input type="time" class="form-control" name="aptTime" id="aptTime" />
            </div>
          </div>

          <div class="form-group form-row">
            <label class="col-md-2 text-md-right" for="aptNotes">Apt. Notes</label>
            <div class="col-md-10">
              <textarea
                class="form-control"
                rows="4"
                cols="50"
                name="aptNotes"
                id="aptNotes"
                placeholder="Appointment Notes"
              ></textarea>
            </div>
          </div>

          <div class="form-group form-row mb-0">
            <div class="offset-md-2 col-md-10">
              <button type="submit" class="btn btn-primary d-block ml-auto">Add Appointment</button>
              <button v-on:click="sendMessage('hello')">Send Message</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "Test2",
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      name: "xxx",
    };
  },
  methods: {
    sendMessage: function(message) {
      console.log("Hello");
      console.log(this.connection);
      this.connection.send(message);
    }
  },
  mounted() {
    console.log("Starting connection to WebSocket Server");
    // this.connection = new WebSocket("wss://echo.websocket.org")
    // this.connection = new WebSocket("wss://test-api-gateway.apthai.com/api/v1/payment/ws");
    this.connection = new WebSocket("ws://localhost:8112/api/v1/payment/ws");

    this.connection.onmessage = function(event) {
      console.log(event);
    };
    this.connection.onopen = function(event) {
      console.log(event);
      console.log("Successfully connected to the echo websocket server...");
    };
  }
};
</script>

<style lang="scss" scoped>
</style>