<template>
  <!--  Template router : ruovvcnnlc -->
  <div class="wrapper">
  <v-container>
      <div class="header-bar">
        <v-img
          class="shrink mt-5 ml-5 mr-2"
          contain
          src="~@/assets/linked-in-logo.png"
          width="135"
          height="34"
        />
      </div>

      <div class="row mt-8">
        <div class="col-sm-12">
          <h1 class="heading-title">Make the most of your professional life</h1>
        </div>
        <div class="box-signin col-sm-5" style="margin: 0 auto;">
          <div class="card box-linkedin rounded-lg">
            <div class="card-body">
              <form>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <span class="label-input">Email</span>
                    <input
                      type="text"
                      class="form-control"
                      id="inputUsername"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <span class="label-input">Password (6+ characters)</span>
                    <input
                      type="password"
                      class="form-control"
                      id="inputNewPassword"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <p class="join-policy-text">
                      By clicking Agree & Join, you agree to the LinkedIn <span class="link-policies">User Agreement</span>, <span class="link-policies">Privacy Policy</span>, and <span class="link-policies">Cookie Policy</span>.
                    </p>
                    <button
                      type="button"
                      class="btn mt-2 btn-agree-join w-100"
                      @click="submitForm"
                    >
                      <b>Agree & Join</b>
                    </button>
                  </div>
                </div>

                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <div class="text-or-wrapper mt-5 mb-5">
                      <div class="text-or">or</div>
                    </div>
                  </div>
                </div>

                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1">
<button
                      type="button"
                      class="btn btn-sign-gmail w-100"
                      @click="submitForm"
                    >
                    <img
                    class="mr-2"
                    src="~@/assets/gmail-sign-in.png"
                    width="30"
                  />
                     Continue with Google
                    </button>
                  </div>
                </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PhishingMail2023Dec04',
  data() {
    return {
      icon: '/linkedin.ico',
      url: null,
      UserID: '',
    };
  },
  mounted() {
    this.initIcon();
    this.setHeaderTitle();
    this.UserID = this.$route.query?.UserID ?? '';

    console.log('mounted() => UserID = ' + this.UserID);
  },
  created() {
    this.sendData(
      'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-one'
    );
  },
  methods: {
    setHeaderTitle() {
      document.title = 'Sign Up | Linkedin..';
    },
    initIcon() {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.icon;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    submitForm(event) {
      event.preventDefault();
      console.log('submitForm', event);
      this.sendData(
        'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-two'
      );
      this.$router.push('/sdsafrkmk');
    },
    sendData(url) {
      this.UserID = this.$route.query?.UserID ?? '';
      console.log('sendData() => UserID = ' + this.UserID);
      this.url = url + '?UserID=' + this.UserID;

      /* API Write Log to Use */
      axios.post(this.url, {}).then((response) => {
        console.log(response.data);
      });
    },
  },
};
</script>

<style scoped>
.header-bar {
  border: 0;
  box-shadow: unset;
  background: transparent !important;
}

.card-body {
  padding: 2rem 1.25rem;
}

.label-input {
  color: #000000bf;
  font-size: 14px;
  font-weight: 600;
}

.join-policy-text {
  font-size:14px;
}

.text-or-wrapper {
  border: 1px solid #dfdfdf;
  text-align: center;
  height: 0;
}

.text-or {
  background-color: #FFFFFF;
    width: 50px;
    margin: 0 auto;
    margin-top: -13px;
}

.heading-title {
  margin: 0 auto;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
}

.box-linkedin {
  border-radius: 10px;
  border: 0;
}

.wrapper {
  background-color: #f3f2f0;
  height: 100vh;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.container {
  background-color: #f3f2f0 !important;
}


h3 {
  color: #999;
  font-size: 20px;
  font-weight: bold;
}
.btn-agree-join {
  background-color: #0a66c2;
  color: white;
  box-shadow: 0 0 0 1px;
  height: 48px;
  border-radius: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.btn-sign-gmail {
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 0 0 1px;
  height: 48px;
  border-radius: 24px;
  text-align: center;
  font-size: 16px;
}

.btn-sign-gmail:hover {
  background-color: #f2f8fe;
}
.btn-agree-join:hover {
  box-shadow: 0 0 0 1px #0000 !important;
  background-color: #004182 !important;
  color: #FFFFFF !important;
}

.link-policies {
  color: #0a66c2;
  font-weight: 500;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn-sign-gmail {
    font-size: 12px;
  }

  .box-signin.col-sm-5 {
    flex: 0 0 55.666667%;
    max-width: 55.666667%;
  }
}
</style>
