<template>
  <!--  Template router : ruovvcnnlc -->
  <div class="wrapper">
    <v-container>
      <div class="row">
        <div class="col-sm-4 offset-sm-4">
          <div class="card rounded-lg">
            <div class="card-body">
              <form>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <h3 class="mb-0">กรุณาเข้าสู่ระบบ</h3>
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <input
                      type="text"
                      class="form-control"
                      id="inputUsername"
                      placeholder="Username"
                    />
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <input
                      type="password"
                      class="form-control"
                      id="inputNewPassword"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div class="form-group row my-0">
                  <div class="col-sm-10 offset-sm-1 my-0 py-2">
                    <button
                      type="button"
                      class="btn btn-reddark w-100"
                      @click="submitForm"
                    >
                      <b>LOGIN</b>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PhishingMail01',
  data() {
    return {
      url: null,
      UserID: '',
    };
  },
  mounted() {
    // this.parameters = this.$route.query
    // console.log(this.parameters)
    this.UserID = this.$route.query.UserID;

    console.log('mounted() => UserID = ' + this.UserID);
  },
  created() {
    this.sendData(
      'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-one'
    );
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      console.log('submitForm', event);
      this.sendData(
        'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-two'
      );
      this.$router.push('/sdsafrkmk');
    },
    sendData(url) {
      this.UserID = this.$route.query.UserID;
      console.log('sendData() => UserID = ' + this.UserID);
      this.url = url + '?UserID=' + this.UserID;

      /* API Write Log to Use */
      axios.post(this.url, {}).then((response) => {
        console.log(response.data);
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #ccc;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
h3 {
  color: #999;
  font-size: 20px;
  font-weight: bold;
}
.btn-reddark {
  background-color: #820000;
  color: white;
}
</style>
