<template>
  <div id="axiosForm">
    <div class="loader" v-if="loading">
      <span class="helper"></span>
      <img class="loaderImg" src="../assets/ajax-loader.gif">
    </div>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Login form</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <fieldset :disabled="loading">
                <v-text-field
                  v-model="username"
                  name="login"
                  label="Login"
                  type="text"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                ></v-text-field>
                </fieldset>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="register()">Register</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import liff from "@line/liff";
import axios from "axios";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      success: false,
      error: false,
      loading: false,
      username: "",
      password: "",
      userId: "",
      messages: "",
      submitted: false,
    };
  },
  mounted() {
    liff
      .init({
        liffId: "1653928950-7deBy2Yv",
      })
      .then(() => {
        console.log("LIFF initialize finished");
        // TODO: get user profile
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then((profile) => {
              this.userId = profile.userId;

              /* API Write Log to Use */
              axios
                .post("/addlogchatbot", {
                  name: this.viewname,
                  userid: this.userId,
                })
                .then((response) => {
                  console.log(response.data);
                });
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          console.log("LIFF is not logged in");
        }
      })
      .catch((err) => {
        console.error("Error initialize LIFF: ", err);
      });
  },
  methods: {
    async register() {
      this.loading = true;

      /* API Write Log to Use */
      axios
        .post("/checkauthorized", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          this.reset();
          this.success = true;

          console.log(response.data);
          this.messages =
            "register=>emp: " + this.username + ", password: ********";

          liff
            .sendMessages([
              {
                type: "text",
                text: this.messages,
              },
            ])
            .then(() => {
              console.log("message sent");
            })
            .catch((err) => {
              console.log("error", err);
            });

          liff.closeWindow();
        })
        .catch((err) => {
          this.error = true;
          console.log("Error :", err);
          // this.messages = 'can not sign up, you are unauthorized!!';
          this.messages =
            "Login failed. Please verify your username or password.";

          liff
            .sendMessages([
              {
                type: "text",
                text: this.messages,
              },
            ])
            .then(() => {
              console.log("message sent");
            })
            .catch((err) => {
              console.log("error", err);
            });

          liff.closeWindow();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reset() {
      this.success = false;
      this.error = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "node_modules/bootstrap/scss/bootstrap";
.v-application .primary {
  background-color: #c92028 !important;
  border-color: #c92028 !important;
}

#axiosForm {
  /* Components Root Element ID */
  position: relative;
}
.loader {
  /* Loader Div Class */
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #eceaea;
  background-image: url("~@/assets/ajax-loader.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.loaderImg {
  vertical-align: middle;
  max-height: 100px;
  max-width: 160px;
}
</style>
