<template>
  <div>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://i.ibb.co/8Bh5MgL/aplogo2018.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://i.ibb.co/crLYCHt/text-apthai.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn href="https://www.apthai.com/th/home" target="_blank" text>
        <span class="ml-auto emp_txt">EMPOWER LIVING</span
        ><span class="ml-3" STYLE="font-size:7.0pt">v12.0.20</span>
        <v-icon class="ml-auto">mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <!-- <HelloWorld/> -->
      <slot></slot>
    </v-content>
  </div>
</template>
<script>
export default {
  name: 'DefaultLayout',
};
</script>

<style lang="scss">
@import '../../../node_modules/bootstrap/scss/bootstrap';
.v-application .primary {
  background-color: #c92028 !important;
  border-color: #c92028 !important;
}
</style>
