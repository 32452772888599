import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

import Test from '../components/Test.vue';
import WalkSummary from '../components/WalkSummary.vue';
import WalkSummaryByBG from '../components/WalkSummaryByBG.vue';
import WalkSummaryBySubBG from '../components/WalkSummaryBySubBG.vue';
import WalkSummaryByProj from '../components/WalkSummaryByProj.vue';
import ChatbotLog from '../components/ChatbotLog.vue';
import ExecutiveReportView from '../components/ExecutiveReportView.vue';
import Dashboard4Indi from '../components/Dashboard4Indi.vue';
import WHTOneTimeValidate from '../components/WHTOneTimeValidate.vue';
import LLDashboardbyProj from '../components/LLDashboardByProj.vue';
import Test2 from '../components/Test2';

// Executive Folder Dashboard
import DSExecutiveWTD from '../components/ExecutiveDS/DSExecutiveWTD.vue';
import DSExecutiveBG2GCN from '../components/ExecutiveDS/DSExecutiveBG2GCN.vue';
import DSExecutiveUnitFigure from '../components/ExecutiveDS/DSExecutiveUnitFigure.vue';
import DSExecutiveUnitFigureSubBG from '../components/ExecutiveDS/DSExecutiveUnitFigureSubBG.vue';
import DSExecutiveInventoryMonitoring from '../components/ExecutiveDS/DSExecutiveInventoryMonitoring.vue';
import DSExecutiveInventoryMonitoringSubBG from '../components/ExecutiveDS/DSExecutiveInventoryMonitoringSubBG.vue';

// Phishing Mail Template Routing
import PhishingMail00 from '../components/PhishingMail/PhishingMail00.vue';
import PhishingMail01 from '../components/PhishingMail/PhishingMail01.vue';
import PhishingMail02 from '../components/PhishingMail/PhishingMail02.vue';
import UnderConstruction from '../components/PhishingMail/UnderConstruction.vue';

// Phishing Mail Template New (DEC 2023)
import PhishingMail2023Dec01 from '../components/PhishingMail/Dec2023/PhishingMail2023Dec01.vue';
import PhishingMail2023Dec02 from '../components/PhishingMail/Dec2023/PhishingMail2023Dec02.vue';
import PhishingMail2023Dec03 from '../components/PhishingMail/Dec2023/PhishingMail2023Dec03.vue';
import PhishingMail2023Dec04 from '../components/PhishingMail/Dec2023/PhishingMail2023Dec04.vue';

// Phishing Mail Template New (DEC 2024)
import PhishingMail2024Dec05 from '../components/PhishingMail/Dec2024/PhishingMail2024Dec05.vue';
import PhishingMail2024Dec06 from '../components/PhishingMail/Dec2024/PhishingMail2024Dec06.vue';
import PhishingMail2024Dec07 from '../components/PhishingMail/Dec2024/PhishingMail2024Dec07.vue';
// import PhishingMail2024Dec08 from '../components/PhishingMail/Dec2024/PhishingMail2024Dec08.vue';

// Performance LCM Dashboard for BG1
import DSBLPerformanceLCM from '../components/PerformanceLCM/DSBLPerformanceLCM.vue';
import DSCCPerformanceLCM from '../components/PerformanceLCM/DSCCPerformanceLCM.vue';
import DSBG1BLBySubBG from '../components/PerformanceLCM/DSBG1BLBySubBG.vue';

// Promotion
import EvoucherTrue from '../components/Promotion/EvoucherTrue.vue';
import EvoucherTrueTest from '../components/Promotion/EvoucherTrueTest.vue';

// LeadLag Chatbot Dashboard
import LeadLagForChatbot from '../components/LeadLag/LeadLagForChatbot.vue';

// LeadLag Chatbot Dashboard
import PerformanceLCForChatbot from '../components/PerformanceLC/PerformanceLC.vue';
import PerformanceLCMForChatbot from '../components/PerformanceLC/PerformanceLCM.vue';

import BlankLayout from '../components/Layout/BlankLayout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/walksummary',
    name: 'WalkSummary',
    component: WalkSummary,
  },
  {
    path: '/logchatbot',
    name: 'ChatbotLog',
    component: ChatbotLog,
  },
  {
    path: '/exrptview',
    name: 'ExecutiveReportView',
    component: ExecutiveReportView,
  },
  {
    path: '/walkbg',
    name: 'WalkSummaryByBG',
    component: WalkSummaryByBG,
  },
  {
    path: '/walksubbg',
    name: 'WalkSummaryBySubBG',
    component: WalkSummaryBySubBG,
  },
  {
    path: '/ds4indi',
    name: 'Dashboard4Indi',
    component: Dashboard4Indi,
  },
  {
    path: '/walkbyproj',
    name: 'WalkSummaryByProj',
    component: WalkSummaryByProj,
  },
  {
    path: '/llbyproj',
    name: 'LLDashboardByProj',
    component: LLDashboardbyProj,
  },
  {
    path: '/whtonetime',
    name: 'WHTOneTimeValidate',
    component: WHTOneTimeValidate,
  },
  {
    path: '/test2',
    name: 'Test2',
    component: Test2,
  },
  {
    path: '/ex/dsexwtd',
    name: 'DSExecutiveWTD',
    component: DSExecutiveWTD,
  },
  {
    path: '/ex/bg2summarygcn',
    name: 'DSExecutiveBG2GCN',
    component: DSExecutiveBG2GCN,
  },
  {
    path: '/bl/performance',
    name: 'DSBLPerformanceLCM',
    component: DSBLPerformanceLCM,
  },
  {
    path: '/cc/performance',
    name: 'DSCCPerformanceLCM',
    component: DSCCPerformanceLCM,
  },
  {
    path: '/bg1/bl/bysubbg',
    name: 'DSBG1BLBySubBG',
    component: DSBG1BLBySubBG,
  },
  {
    path: '/promtrue',
    name: 'EvoucherTrue',
    component: EvoucherTrue,
  },
  {
    path: '/promtruetest',
    name: 'EvoucherTrueTest',
    component: EvoucherTrueTest,
  },
  {
    path: '/ll/leadlagcard',
    name: 'LeadLagForChatbot',
    component: LeadLagForChatbot,
  },
  {
    path: '/lc/performance',
    name: 'PerformanceLCForChatbot',
    component: PerformanceLCForChatbot,
  },
  {
    path: '/lcm/performance',
    name: 'PerformanceLCMForChatbot',
    component: PerformanceLCMForChatbot,
  },
  {
    path: '/ex/unitfigure',
    name: 'DSExecutiveUnitFigure',
    component: DSExecutiveUnitFigure,
  },
  {
    path: '/ex/subbg/unitfigure',
    name: 'DSExecutiveUnitFigureSubBG',
    component: DSExecutiveUnitFigureSubBG,
  },
  {
    path: '/ex/intentory',
    name: 'DSExecutiveInventoryMonitoring',
    component: DSExecutiveInventoryMonitoring,
  },
  {
    path: '/ex/subbg/intentory',
    name: 'DSExecutiveInventoryMonitoringSubBG',
    component: DSExecutiveInventoryMonitoringSubBG,
  },
  {
    path: '/rfofesfexa',
    name: 'PhishingMail00',
    component: PhishingMail00,
    meta: {
      layout: BlankLayout,
    },
  },
  {
    path: '/ruovvcnnlc',
    name: 'PhishingMail01',
    component: PhishingMail01,
    meta: {
      layout: BlankLayout,
    },
  },
  {
    path: '/efxzbyjlze',
    name: 'PhishingMail02',
    component: PhishingMail02,
    meta: {
      layout: BlankLayout,
    },
  },
  {
    path: '/sdsafrkmk',
    name: 'UnderConstruction',
    component: UnderConstruction,
    meta: {
      layout: BlankLayout,
    },
  },
  {
    // ap intranet template Dec 2023 mail-01
    path: '/ygeqhvsjno',
    name: 'PhishingMail2023Dec01',
    component: PhishingMail2023Dec01,
    meta: {
      layout: BlankLayout
    },
  },
  {
    // share file one drive template Dec 2023 mail-02
    path: '/hixlrvdkfi',
    name: 'PhishingMail2023Dec02',
    component: PhishingMail2023Dec02,
    meta: {
      layout: BlankLayout
    },
  },
  {
    // scb template Dec 2023 mail-03
    path: '/gktmbekqcm',
    name: 'PhishingMail2023Dec03',
    component: PhishingMail2023Dec03,
    meta: {
      layout: BlankLayout
    },
  },
  {
    // linkedin template Dec 2023 mail-04
    path: '/ompzahcipz',
    name: 'PhishingMail2023Dec04',
    component: PhishingMail2023Dec04,
    meta: {
      layout: BlankLayout
    },
  },
  {
    // 2024 COACH template-05
    path: '/submitprofile',
    name: 'PhishingMail2024Dec05',
    component: PhishingMail2024Dec05,
    meta: {
      layout: BlankLayout
    },
  },
  {
    // 2024 phyathai template-06 
    path: '/checkup',
    name: 'PhishingMail2024Dec06',
    component: PhishingMail2024Dec06,
    meta: {
      layout: BlankLayout
    },
  },
  {
    // 2024 scb check identify template-07 
    path: '/scbsubmit',
    name: 'PhishingMail2024Dec07',
    component: PhishingMail2024Dec07,
    meta: {
      layout: BlankLayout
    },
  },
  {
    // 2024 ap reset password template-08 
    path: '/sharedrive',
    name: 'PhishingMail2023Dec02',
    component: PhishingMail2023Dec02,
    meta: {
      layout: BlankLayout
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
