<template>
  <!--  Template router : ruovvcnnlc -->
  <div class="wrapper">
    <v-app-bar app class="header-bar" purple>
      <div class="mt-3 d-flex align-center">
        <v-img
          class="shrink mr-2"
          contain
          src="~@/assets/SCB_BK.png"
          width="200"
        />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-container>
      <div class="row box-login-wrapper">
        <div class="box-login col-md-4">
          <div class="card" style="border:0;background: transparent;">
            <div class="card-body">
              <form>
                <div class="form-group row my-0">
                  <div class="col-sm-12">
                    <h1 class="mb-5 heading-title ml-8">Login To SCB Easy.</h1>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-11">
                    <input
                      type="text"
                      class="form-control input-form ml-8"
                      id="inputUsername"
                      placeholder="Username"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-11">
                    <input
                      type="password"
                      class="form-control input-form ml-8"
                      id="inputNewPassword"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div class="form-group col-sm-11 row  mt-5">
                  <div class="">
                    <button
                      type="button"
                      class="btn offset-sm-1 ml-8 btn-button w-100"
                      @click="submitForm"
                    >
                      LOGIN
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PhishingMail2023Dec03',
  data() {
    return {
      icon: '/scb_favicon.ico',
      url: null,
      UserID: '',
    };
  },
  mounted() {
    this.initIcon();
    this.setHeaderTitle();
    // this.parameters = this.$route.query
    // console.log(this.parameters)
    this.UserID = this.$route.query?.UserID ?? '';

    console.log('mounted() => UserID = ' + this.UserID);
  },
  created() {
    this.sendData(
      'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-one'
    );
  },
  methods: {
    setHeaderTitle() {
      document.title = 'Welcome to SCB Easy';
    },
    initIcon() {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.icon;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    submitForm(event) {
      event.preventDefault();
      console.log('submitForm', event);
      this.sendData(
        'https://test-api-gateway.apthai.com/api/v1/crmdw/pmail/step-two'
      );
      this.$router.push('/sdsafrkmk');
    },
    sendData(url) {
      this.UserID = this.$route.query?.UserID ?? '';
      console.log('sendData() => UserID = ' + this.UserID);
      this.url = url + '?UserID=' + this.UserID;

      /* API Write Log to Use */
      axios.post(this.url, {}).then((response) => {
        console.log(response.data);
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #ccc;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-title {
  color: #676464;
  font-size: 2.188rem;
  font-weight: bold;
}

.container {
  max-width: 95%;
  margin-top: 90px;
  height: 80vh;
  background: rgb(146,102,163) !important;
  background: linear-gradient(0deg, rgba(146,102,163,1) 0%, rgba(236,227,240,1) 90%) !important;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.header-bar {
  min-height: 80px !important;
  background-color: #462279 !important;
}

.box-login {
  margin:0 auto;
}

.box-login-wrapper {
  margin-top: 10%;
}

/* Portrait phones and smaller */
@media (max-width: 480px) {

  .heading-title.ml-8 {
    font-size: 1.875rem;
    margin-left: 0 !important;
  }

  .input-form.ml-8 {
    margin-left: 0 !important;
  }

  .btn-button.ml-8 {
    margin-left: 0 !important;
  }
  .box-login-wrapper {
    margin-top: 0;
  }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .box-login {
    flex: 0 0 41%;
    max-width: 41%;
  }
  .box-login-wrapper {
    margin-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .box-login {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .heading-title {
    font-size: 1.8rem;
  }
  .box-login-wrapper {
    margin-top: 0;
  }
}



.btn-button {
  background-color: #716f75;
  color: white;
}
.btn-button:hover {
  color: white;
  opacity:0.8;
}
</style>
