<template>
  <!--  Template router : efxzbyjlze -->
  <div class="wrapper">
    <v-container>
      <div class="row">
        <div class="col-sm-6 offset-sm-3 text-center">
          <img src="@/assets/60028-Converted.png" />
          <h1>THIS PAGE IS UNDER CONSTRUCTION</h1>
          <h2>We're working on it!</h2>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
// import BlankLayout from '../Layout/BlankLayout.vue';

export default {
  name: 'UnderConstruction',
  metaInfo: {
    layout: 'blank-layout',
  },
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>

<style scoped>
h1,
h2 {
  font-weight: bold;
}
h2 {
  color: blue;
}
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
