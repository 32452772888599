<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <iframe class="embed-responsive-item resp-iframe" :src="url" width="375" height="1200px" frameborder="0" scrolling="no" allowfullscreen></iframe>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import liff from "@line/liff";
import axios from 'axios';

export default {
   name: 'DSExecutiveInventoryMonitoringSubBG',
   data() {
       return {
           viewname: 'Executive Dashboard Inventory Mornitoring SubBG',
           ticket: '',
           url: '',
           userid: ''
       };
   },
  mounted() {
    liff
      .init({
        liffId: "1653928950-j1y98noA",
      })
      .then(() => {
        console.log("LIFF initialize finished");
        // TODO: get user profile
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then((profile) => {
              this.userId = profile.userId;
    
              /* API Write Log to Use */
              axios.post('/addlogchatbot', {
                name: this.viewname,
                userid: this.userId
              })
              .then(response => {
                console.log(response.data);
              });
              
              /* API Get ticket to authorized tableau */
              axios.get("/gettableauticket").then((response) => {
                this.ticket = response.data["message"];
                console.log(this.ticket);
                
                this.url = process.env.VUE_APP_IT_TABLEAU_URL + "/" +
                this.ticket +
                "/views/CRMProductStatusSubBG/DSProductStatusSubBG?:refresh=true&:embed=yes&:toolbar=no&:tabs=no&UserGUID=" + this.userId;
              });
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          console.log("LIFF is not logged in");
              this.userId = "U80a30a5bad4ea0f5f7995e5050ab8d7e";
              
              /* API Get ticket to authorized tableau */
              axios.get("/gettableauticket").then((response) => {
                this.ticket = response.data["message"];
                console.log(this.ticket);
                
                this.url = process.env.VUE_APP_IT_TABLEAU_URL + "/" +
                  this.ticket +
                  "/views/CRMProductStatusSubBG/DSProductStatusSubBG?:refresh=true&:embed=yes&:toolbar=no&:tabs=no&UserGUID=" + this.userId;
                  console.log(this.url);
              });
        }
      })
      .catch((err) => {
        console.error("Error initialize LIFF: ", err);
      });
  },
};
</script>


<style lang="scss" scoped>

</style>